import { Card, Col, Form, Radio, Row, Select, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetLead } from "../hooks/use-get-lead";
import Input from "antd/es/input";
import VuiFormActionButton from "../../../components/form-action-button";
import VuiSelect from "../../../components/select";
import { useCallback, useEffect } from "react";
import VuiPageTitle from "../../../components/page-title";
import { useFormLead } from "../hooks/use-form-lead";
import { LeadFormData, LeadPayloadType } from "./interface";
import { convertToSelectValue } from "../../../components/select/function";
import UserRepository from "../../../repositories/user-repository";
import CustomerRepository from "../../../repositories/customer-repository";
import VuiDatePicker from "../../../components/vui-date-picker";
import moment from "moment";
import ConstantRepository from "../../../repositories/constant-repository";
import IndustryRepository from "../../../repositories/industry-repository";
import ProductRepository from "../../../repositories/product-repository";

const LeadFormModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const title = id ? t("pages.lead.edit.title") : t("pages.lead.add.title");
  const { loadDetailData, isOnFetchingDetailData, detailData } = useGetLead();
  const { submitLoading, onSubmit } = useFormLead();

  const [form] = Form.useForm();

  const isExistingClient = Form.useWatch("existing_client", form);
  const currentEmails = Form.useWatch("emails", form);
  const currentPhones = Form.useWatch("phones", form);

  const onFinish = useCallback(
    async (data: LeadFormData) => {
      const normalizeData: LeadPayloadType = {
        ...data,
        date: moment(data.date).format("YYYY-MM-DD HH:mm"),
        customer_id: data.existing_client
          ? (data.customer_id?.value as number)
          : undefined,
        type_id: !data.existing_client
          ? (data.type_id?.value as number)
          : undefined,
        industry_id: !data.existing_client
          ? (data.industry_id?.value as number)
          : undefined,
        lead_name: !data.existing_client ? data.lead_name : undefined,
        priority_id: data.priority_id?.value as number,
        assign_id: data.assign_id?.value as number,
        product_ids: data.product_ids?.map((item) => item.value as number),
      };

      await onSubmit(normalizeData, id);
    },
    [id, onSubmit]
  );

  useEffect(() => {
    if (id) {
      (async () => {
        await loadDetailData(id, {
          with: [
            "customer",
            "type",
            "industry",
            "priority",
            "assign",
            "products",
          ],
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id && detailData) {
      form.setFieldsValue({
        ...detailData,
        date: moment(detailData.date),
        customer_id: convertToSelectValue(detailData.customer ?? null),
        type_id: detailData?.type
          ? {
              label: detailData?.type?.label,
              value: detailData?.type?.id,
            }
          : undefined,
        industry_id: convertToSelectValue(detailData.industry ?? null),
        priority_id: {
          label: detailData?.priority?.label,
          value: detailData?.priority?.id,
        },
        assign_id: convertToSelectValue(detailData.assign ?? null),
        product_ids: detailData.products?.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData]);

  return (
    <>
      <VuiPageTitle title={title} onBackLink={id ? `/lead/${id}` : "/lead"} />

      <Spin size="large" spinning={isOnFetchingDetailData}>
        <Form
          form={form}
          layout={"vertical"}
          initialValues={{
            existing_client: false,
            date: moment(),
          }}
          onFinish={onFinish}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Card>
                <Row gutter={[62, 16]}>
                  <Col xs={24} md={10}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24}>
                        <Form.Item name="existing_client">
                          <Radio.Group>
                            <Radio.Button value={false}>
                              {t("common.text.newClient")}
                            </Radio.Button>
                            <Radio.Button value={true}>
                              {t("common.text.existingClient")}
                            </Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      {isExistingClient && (
                        <Col xs={24}>
                          <Form.Item
                            name="customer_id"
                            label={t("common.form.client.label")}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: t("common.form.client.label"),
                                }),
                              },
                            ]}
                          >
                            <VuiSelect
                              repository={CustomerRepository}
                              placeholder={t("common.form.client.placeholder")}
                            />
                          </Form.Item>
                        </Col>
                      )}
                      {!isExistingClient && (
                        <>
                          <Col xs={24} md={12}>
                            <Form.Item
                              name="type_id"
                              label={t("common.form.clientType.label")}
                              rules={[
                                {
                                  required: true,
                                  message: t("validation.required", {
                                    item: t("common.form.clientType.label"),
                                  }),
                                },
                              ]}
                            >
                              <VuiSelect
                                repository={ConstantRepository}
                                repositoryParams={{
                                  for: "customer_type",
                                }}
                                labelKey="label"
                                placeholder={t(
                                  "common.form.clientType.placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12}>
                            <Form.Item
                              name="industry_id"
                              label={t("common.form.industry.label")}
                              rules={[
                                {
                                  required: true,
                                  message: t("validation.required", {
                                    item: t("common.form.industry.label"),
                                  }),
                                },
                              ]}
                            >
                              <VuiSelect
                                repository={IndustryRepository}
                                placeholder={t(
                                  "common.form.industry.placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              name="lead_name"
                              label={t("common.form.leadName.label")}
                              rules={[
                                {
                                  required: true,
                                  message: t("validation.required", {
                                    item: t("common.form.leadName.label"),
                                  }),
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder={t(
                                  "common.form.leadName.placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Col>

                  <Col xs={24} md={14}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={8}>
                        <Form.Item
                          name="date"
                          label={t("common.form.date.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.date.label"),
                              }),
                            },
                          ]}
                        >
                          <VuiDatePicker />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8}>
                        <Form.Item
                          name="priority_id"
                          label={t("common.form.priority.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.priority.label"),
                              }),
                            },
                          ]}
                        >
                          <VuiSelect
                            repository={ConstantRepository}
                            repositoryParams={{
                              for: "priority_lead",
                            }}
                            labelKey="label"
                            placeholder={t("common.form.priority.placeholder")}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8}>
                        <Form.Item
                          name="assign_id"
                          label={t("common.form.assignee.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.assignee.label"),
                              }),
                            },
                          ]}
                        >
                          <VuiSelect
                            repository={UserRepository}
                            placeholder={t("common.form.assignee.placeholder")}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          name="emails"
                          label={t("common.form.emails.label")}
                          rules={[
                            {
                              required: (currentPhones?.length ?? 0) === 0,
                              message: t("validation.required", {
                                item: t("common.form.emails.label"),
                              }),
                            },
                          ]}
                        >
                          <Select
                            mode="tags"
                            style={{ width: "100%" }}
                            placeholder={t("common.form.emails.placeholder")}
                            options={[]}
                            open={false}
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          name="phones"
                          label={t("common.form.phones.label")}
                          rules={[
                            {
                              required: (currentEmails?.length ?? 0) === 0,
                              message: t("validation.required", {
                                item: t("common.form.phones.label"),
                              }),
                            },
                          ]}
                        >
                          <Select
                            mode="tags"
                            style={{ width: "100%" }}
                            placeholder={t("common.form.phones.placeholder")}
                            options={[]}
                            open={false}
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          name="product_ids"
                          label={t("common.form.potentialServices.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.potentialServices.label"),
                              }),
                            },
                          ]}
                        >
                          <VuiSelect
                            mode="multiple"
                            repository={ProductRepository}
                            placeholder={t(
                              "common.form.potentialServices.placeholder"
                            )}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  {!id && (
                    <Col xs={24}>
                      <Form.Item
                        name="note"
                        label={t("common.form.note.label")}
                      >
                        <Input.TextArea
                          rows={4}
                          placeholder={t("common.form.note.placeholder")}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Card>
            </Col>

            <Col xs={24}>
              <VuiFormActionButton
                cancelBtnLink={id ? `/lead/${id}` : "/lead"}
                isLoading={submitLoading}
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};

export default LeadFormModule;
