import { useCallback, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { handleBackendError, openNotification } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PageRepository from "../../../repositories/page-repository";
import { IResource } from "../../../utils/interfaces/resource.interface";
import { Page } from "../../../models/page";
import { PagePayloadType } from "../form/interface";

export const useFormPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const onSubmit = useCallback(
    async (data: PagePayloadType, id: string) => {
      setSubmitLoading(true);

      await PageRepository.update(id, data)
        .then((response: AxiosResponse<IResource<Page>>) => {
          navigate("/page");
          openNotification(
            "success",
            t(`notification.success.${id ? "updateItem" : "createItem"}`, {
              item: t("common.text.page"),
            })
          );
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, navigate]
  );

  return {
    onSubmit,
    submitLoading,
  };
};
