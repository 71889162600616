import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/auth";
import InvoiceRequestWidget from "./components/invoice-request-widget";
import { Col, Row, Space } from "antd";
import InvoiceDueWidget from "./components/invoice-due-widget";

const DashboardCrmModule = () => {
  const { t } = useTranslation();
  const { state } = useAuth();

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }} size={32}>
        <Row gutter={[24, 24]}>
          {state.user?.permissions.includes("widget.invoice-requested") && (
            <Col md={6} xs={24}>
              <InvoiceRequestWidget />
            </Col>
          )}

          {state.user?.permissions.includes("widget.invoice-due") && (
            <Col md={6} xs={24}>
              <InvoiceDueWidget />
            </Col>
          )}
        </Row>
      </Space>
    </>
  );
};

export default DashboardCrmModule;
