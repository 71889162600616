import { Card, Col, Form, Row, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetPage } from "../hooks/use-get-page";
import Input from "antd/es/input";
import VuiFormActionButton from "../../../components/form-action-button";
import { useCallback, useEffect } from "react";
import VuiPageTitle from "../../../components/page-title";
import { useFormPage } from "../hooks/use-form-page";
import { PageFormData, PagePayloadType } from "./interface";

const PageFormModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { loadPageData, isOnFetchingPageData, pageData } = useGetPage();
  const { submitLoading, onSubmit } = useFormPage();

  const [form] = Form.useForm<PageFormData>();

  const onFinish = useCallback(
    async (data: PageFormData) => {
      const normalizeData: PagePayloadType = {
        ...data,
        meta_title: data.meta.meta_title,
        meta_description: data.meta.meta_description,
        meta_keyword: data.meta.meta_keyword,
      };

      await onSubmit(normalizeData, id ?? "");
    },
    [id, onSubmit]
  );

  useEffect(() => {
    if (id) {
      (async () => {
        await loadPageData(id, { with: "meta" });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id && pageData) {
      form.setFieldsValue({
        ...pageData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData]);

  return (
    <>
      <VuiPageTitle title={`Edit ${pageData?.name} Page`} onBackLink="/page" />

      <Spin size="large" spinning={isOnFetchingPageData}>
        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col md={12} xs={24}>
              <Card title={t("common.text.seo")}>
                <Form.Item
                  name={["meta", "meta_title"]}
                  label={t("common.form.metaTitle.label")}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", {
                        item: t("common.form.metaTitle.label"),
                      }),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={t("common.form.metaTitle.placeholder")}
                  />
                </Form.Item>

                <Form.Item
                  name={["meta", "meta_description"]}
                  label={t("common.form.metaDescription.label")}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", {
                        item: t("common.form.metaDescription.label"),
                      }),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={t("common.form.metaDescription.placeholder")}
                  />
                </Form.Item>

                <Form.Item
                  name={["meta", "meta_keyword"]}
                  label={t("common.form.metaKeyword.label")}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", {
                        item: t("common.form.metaKeyword.label"),
                      }),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={t("common.form.metaKeyword.placeholder")}
                  />
                </Form.Item>
              </Card>
            </Col>

            <Col md={24} xs={24}>
              <VuiFormActionButton
                cancelBtnLink="/page"
                isLoading={submitLoading}
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};

export default PageFormModule;
