import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import WidgetRepository from "../../../../repositories/widget-repository";
import { AxiosResponse } from "axios";
import { IResource } from "../../../../utils/interfaces/resource.interface";
import Widget from "../widget";
import VuiNumberFormat from "../../../../components/number-format";

type InvoiceRequestWidgetType = {
  value: number;
};

const InvoiceRequestWidget = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<number>(0);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);

  const loadData = useCallback(async () => {
    setIsFetchingData(true);

    await WidgetRepository.requestInvoice()
      .then((response: AxiosResponse<IResource<InvoiceRequestWidgetType>>) => {
        setData(response?.data?.data?.value);
      })
      .catch(() => {})
      .finally(() => {
        setIsFetchingData(false);
      });
  }, []);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, [loadData]);

  return (
    <Widget
      title={t("common.text.requestInvoice")}
      isLoading={isFetchingData}
      data={<VuiNumberFormat value={data} />}
      subtitle={t("common.text.request")}
    />
  );
};

export default InvoiceRequestWidget;
