import { Card, Col, Form, Radio, Row, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCategory } from "../hooks/use-get-category";
import Input from "antd/es/input";
import VuiFormActionButton from "../../../components/form-action-button";
import { useCallback, useEffect, useState } from "react";
import VuiPageTitle from "../../../components/page-title";
import { useFormCategory } from "../hooks/use-form-category";
import { CategoryFormData, CategoryPayloadType } from "./interface";
import VuiSelect from "../../../components/select";
import { openNotification } from "../../../utils/helpers";
import ConstantRepository from "../../../repositories/constant-repository";
import VuiAccessible from "../../../components/accessible";
import VuiModalConfirmation from "../../../components/modal-confirmation";

const { TextArea } = Input;
const { Text } = Typography;

const CategoryFormModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const title = id
    ? t("pages.category.detail.title")
    : t("pages.category.add.title");
  const { loadCategoryData, isOnFetchingCategoryData, categoryData } =
    useGetCategory();
  const { submitLoading, onSubmit, onDelete, deleteLoading } =
    useFormCategory();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [form] = Form.useForm<CategoryFormData>();

  const onFinish = useCallback(
    async (data: CategoryFormData) => {
      const normalizeData: CategoryPayloadType = {
        ...data,
        type_id: data.type_id.value as number,
      };

      await onSubmit(normalizeData, id);
    },
    [id, onSubmit]
  );

  const handleCloseModalDelete = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (id) {
      await onDelete(id).then(() => {
        setShowDeleteModal(false);
        openNotification(
          "success",
          t("notification.success.deleteItem", {
            item: t("common.text.category"),
          })
        );
        navigate(`/other-list/category`);
      });
    }
  }, [id, navigate, onDelete, t]);

  useEffect(() => {
    if (id) {
      (async () => {
        await loadCategoryData(id, { with: "type" });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id && categoryData) {
      form.setFieldsValue({
        ...categoryData,
        type_id: {
          label: categoryData?.type?.label,
          value: categoryData?.type?.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryData]);

  return (
    <>
      <VuiPageTitle title={title} onBackLink="/other-list/category" />

      <Spin size="large" spinning={isOnFetchingCategoryData}>
        <Form
          form={form}
          layout={"vertical"}
          initialValues={{
            is_active: true,
          }}
          onFinish={onFinish}
        >
          <Row gutter={[16, 16]}>
            <Col md={18} xs={24}>
              <Card title={t("common.text.information")}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="type_id"
                      label={t("common.form.categoryType.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.categoryType.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect
                        repository={ConstantRepository}
                        repositoryParams={{
                          for: "category_type",
                        }}
                        labelKey="label"
                        placeholder={t("common.form.categoryType.placeholder")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="name"
                      label={t("common.form.categoryName.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.categoryName.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.categoryName.placeholder")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={6} xs={24}>
              <Card title={t("common.text.status")}>
                <Form.Item name="is_active">
                  <Radio.Group>
                    <Radio value={true}>Active</Radio>
                    <Radio value={false}>Not Active</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>
            </Col>

            <Col md={24} xs={24}>
              <VuiFormActionButton
                cancelBtnLink="/other-list/category"
                isLoading={submitLoading}
              />
            </Col>

            {id && (
              <VuiAccessible access="category.destroy">
                <Col xs={24}>
                  <Text
                    className="cursor-pointer"
                    onClick={() => setShowDeleteModal(true)}
                    title={t("common.text.delete")}
                    type="secondary"
                  >
                    {t("common.text.delete")}
                  </Text>
                </Col>
              </VuiAccessible>
            )}
          </Row>
        </Form>
      </Spin>

      <VuiModalConfirmation
        show={showDeleteModal}
        isLoading={deleteLoading}
        onSubmit={handleConfirmDelete}
        onCancel={handleCloseModalDelete}
      />
    </>
  );
};

export default CategoryFormModule;
