import { useCallback, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { ICollection } from "../../../utils/interfaces/collection.interface";
import { handleBackendError } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { IPaginationParams } from "../../../utils/interfaces/pagination-params.interface";
import { IResource } from "../../../utils/interfaces/resource.interface";
import PageRepository from "../../../repositories/page-repository";
import { PageList } from "../list/interface";
import { Page } from "../../../models/page";

export const useGetPage = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PageList[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [isOnFetchingData, setIsOnFetchingData] = useState<boolean>(false);

  const [pageData, setPageData] = useState<Page | null>(null);
  const [isOnFetchingPageData, setIsOnFetchingPageData] =
    useState<boolean>(false);

  const loadData = useCallback(async (params: IPaginationParams) => {
    setIsOnFetchingData(true);

    await PageRepository.all(params)
      .then((response: AxiosResponse<ICollection<PageList[]>>) => {
        setData(response.data.data);
        setTotalData(response.data.meta.total);
      })
      .catch(() => {})
      .finally(() => {
        setIsOnFetchingData(false);
      });
  }, []);

  const loadPageData = useCallback(
    async (id: string, params: any = null) => {
      setIsOnFetchingPageData(true);

      await PageRepository.show(id, params)
        .then((response: AxiosResponse<IResource<Page>>) => {
          setPageData(response.data.data);
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setIsOnFetchingPageData(false);
        });
    },
    [t]
  );

  return {
    data,
    totalData,
    loadData,
    isOnFetchingData,
    loadPageData,
    isOnFetchingPageData,
    pageData,
  };
};
