import { useCallback, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { ICollection } from "../../../utils/interfaces/collection.interface";
import { handleBackendError } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { IPaginationParams } from "../../../utils/interfaces/pagination-params.interface";
import { IResource } from "../../../utils/interfaces/resource.interface";
import InsightRepository from "../../../repositories/insight-repository";
import { InsightList } from "../list/interface";
import { Insight } from "../../../models/insight";

export const useGetInsight = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<InsightList[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [isOnFetchingData, setIsOnFetchingData] = useState<boolean>(false);

  const [insightData, setInsightData] = useState<Insight | null>(null);
  const [isOnFetchingInsightData, setIsOnFetchingInsightData] =
    useState<boolean>(false);

  const loadData = useCallback(async (params: IPaginationParams) => {
    setIsOnFetchingData(true);

    await InsightRepository.all(params)
      .then((response: AxiosResponse<ICollection<InsightList[]>>) => {
        setData(response.data.data);
        setTotalData(response.data.meta.total);
      })
      .catch(() => {})
      .finally(() => {
        setIsOnFetchingData(false);
      });
  }, []);

  const loadInsightData = useCallback(
    async (id: string, params: any = null) => {
      setIsOnFetchingInsightData(true);

      await InsightRepository.show(id, params)
        .then((response: AxiosResponse<IResource<Insight>>) => {
          setInsightData(response.data.data);
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setIsOnFetchingInsightData(false);
        });
    },
    [t]
  );

  return {
    data,
    totalData,
    loadData,
    isOnFetchingData,
    loadInsightData,
    isOnFetchingInsightData,
    insightData,
  };
};
