import api, { createCancelTokenHandler } from "../utils/services/api.service";
import { ENV } from "../constants";

const CredentialRepository = {
  all(params?: any) {
    return api.get(`${ENV.getApiEndPoint()}/api/credential`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show(id: string, params?: any) {
    return api.get(`${ENV.getApiEndPoint()}/api/credential/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  delete: function (id: number | string, params: any = null) {
    return api.delete(`${ENV.getApiEndPoint()}/api/credential/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.delete.name].handleRequestCancellation()
          .token,
    });
  },
  select: function (params: any = null) {
    return api.get(`${ENV.getApiEndPoint()}/api/select/credential`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${ENV.getApiEndPoint()}/api/credential`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
  update: function (id: number | string, payload: any, params: any = null) {
    return api.put(`${ENV.getApiEndPoint()}/api/credential/${id}`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  }
};

const cancelTokenHandlerObject = createCancelTokenHandler(CredentialRepository);

export default CredentialRepository;
