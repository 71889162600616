import { useTranslation } from "react-i18next";
import VuiHelmet from "../../components/helmet";
import { useBreadcrumb } from "../../context/breadcrumb";
import { BreadcrumbActionType } from "../../context/breadcrumb/reducer";
import { useEffect } from "react";
import DashboardCrmModule from "../../modules/dashboard-crm";

const DashboardCrmPage = () => {
  const { t } = useTranslation();
  const { dispatch } = useBreadcrumb();

  useEffect(() => {
    dispatch({
      type: BreadcrumbActionType.Update,
      payload: [
        {
          link: "/",
          title: t("common.text.dashboardCrm"),
          isDisabled: true,
        },
      ],
    });
  }, [dispatch, t]);

  return (
    <>
      <VuiHelmet title={t("common.text.dashboardCrm")} />

      <DashboardCrmModule />
    </>
  );
};

export default DashboardCrmPage;
