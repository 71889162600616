import { DownloadOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";
import { ColumnsType } from "antd/es/table/interface";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import VuiButton from "../../../components/button";
import VuiFormItemView from "../../../components/form-item-view";
import VuiPageTitle from "../../../components/page-title";
import { INVOICE_STATUS_COLOR } from "../../../constants";
import { useBreadcrumb } from "../../../context/breadcrumb";
import { BreadcrumbActionType } from "../../../context/breadcrumb/reducer";
import { useFormInvoice } from "../hooks/use-form-invoice";
import { useGetInvoice } from "../hooks/use-get-invoice";
import {
  InvoicePaidOffFormData,
  InvoicePaidOffPayloadType,
  InvoiceUploadFormData,
  InvoiceUploadPayloadType,
} from "./interface";
import ModalUpload from "../components/modal-upload";
import { InvoicePayment, InvoiceProduct } from "../../../models/invoice";
import VuiNumberFormat from "../../../components/number-format";
import VuiModalConfirmation from "../../../components/modal-confirmation";
import VuiAccessible from "../../../components/accessible";
import {
  inputNumberThousandSeparator,
  openNotification,
} from "../../../utils/helpers";
import VuiDatePicker from "../../../components/vui-date-picker";
import VuiSelect from "../../../components/select";
import ChartOfAccountRepository from "../../../repositories/chart-of-account-repository";
import VuiUploadImage from "../../../components/upload-image";
import { Option } from "antd/lib/mentions";
import { useGetFirstData } from "../hooks/use-get-first-data";

const { Text, Title } = Typography;

type ApprovalType = "approve" | "reject";

const InvoiceDetailModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { loadDetailData, isOnFetchingDetailData, detailData } =
    useGetInvoice();
  const {
    data: defaultPay,
    isOnFetchingData: isOnFetchingDataDefaultPay,
    loadData: loadDefaultPay,
  } = useGetFirstData(ChartOfAccountRepository);
  const title = `${t("common.text.invoice")} | `;
  const {
    uploadLoading,
    onUpload,
    downloadLoading,
    onDownload,
    sendLoading,
    onSend,
    paidOffLoading,
    onSubmitPaidOff,
    isApprovalSubmitting,
    onApproval,
    onDelete,
    deleteLoading,
    onSendReminder,
    sendReminderLoading,
  } = useFormInvoice();
  const { dispatch } = useBreadcrumb();
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [showSendForm, setShowSendForm] = useState(false);
  const [showPaidOffConfirmation, setShowPaidOffConfirmation] = useState(false);
  const [showApprovalConfirmation, setShowApprovalConfirmation] =
    useState<ApprovalType | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [withholdingType, setWithholdingType] = useState("PERCENTAGE");
  const [withholdingRealAmount, setWithholdingRealAmount] = useState<number>(0);
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
  const [showSendReminderForm, setShowSendReminderForm] = useState(false);
  const [form] = Form.useForm();

  const hasPPN = detailData?.is_ppn;
  const invoiceProducts = detailData?.invoice_products;
  const subtotal =
    invoiceProducts
      ?.map((item) => {
        const price = item?.price || 0;
        const quantity = item?.quantity || 0;
        if (!item?.product_id && !item?.gw_product_id) {
          return 0;
        }
        return Number(price) * quantity;
      })
      .reduce((total: number, num: number) => total + num) || 0;

  const ppn = hasPPN ? (11 / 100) * subtotal : 0;

  const total = subtotal + ppn;

  const invoiceEmails = useMemo(() => {
    return detailData?.invoice_emails?.map((item) => item.email).join(", ");
  }, [detailData]);

  const haveEmailContact = useMemo(() => {
    return (detailData?.contact_emails?.length ?? 0) > 0;
  }, [detailData]);

  const haveWhatsappContact = useMemo(() => {
    return (detailData?.contact_whatsapp?.length ?? 0) > 0;
  }, [detailData]);

  const invoiceContactEmails = useMemo(() => {
    return detailData?.contact_emails
      ?.map((contact) => contact.email)
      .join(", ");
  }, [detailData]);

  const invoiceContactWhatsapp = useMemo(() => {
    return detailData?.contact_whatsapp
      ?.map((contact) => contact.phone)
      .join(", ");
  }, [detailData]);

  useEffect(() => {
    if (defaultPay) {
      form.setFieldsValue({
        pay_coa_id: {
          label: defaultPay?.name,
          value: defaultPay?.id,
        },
      });
    }
  }, [defaultPay]);

  const invoiceProductColumns: ColumnsType<InvoiceProduct> = [
    {
      title: t("common.text.product"),
      dataIndex: "product_id",
      key: "product_id",
      render: (text, record) => (
        <Text>{record.product?.name || record?.gw_product_name}</Text>
      ),
    },
    {
      title: t("common.text.description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("common.text.qty"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: t("common.text.units"),
      dataIndex: "unit_id",
      key: "unit_id",
      render: (text, record) => {
        let name = record.unit?.name;
        if (!name) {
          name = record?.gw_product_unit === "LICENSE" ? "License" : "Seat";
        }
        return <Text>{name}</Text>;
      },
    },
    {
      title: `${t("common.text.unitPrice")} (Rp)`,
      dataIndex: "price",
      key: "price",
      render: (text) => <VuiNumberFormat value={text} />,
    },
    {
      title: `${t("common.text.amount")} (Rp)`,
      key: "price",
      render: (text, record) => (
        <VuiNumberFormat value={record?.quantity * record?.price} />
      ),
    },
  ];

  const invoicePaymentColumns: ColumnsType<InvoicePayment> = [
    {
      title: t("common.text.paymentDate"),
      dataIndex: "date",
      key: "date",
      render: (text) => moment(text).format("DD MMM YYYY"),
    },
    {
      title: t("common.text.payToAccount"),
      dataIndex: "pay_coa",
      key: "pay_coa",
      render: (text, record) => record.pay_coa?.name,
    },
    {
      title: t("common.text.paymentMethod"),
      dataIndex: "payment_method",
      key: "payment_method",
      render: (text) => _.startCase(_.toLower(text.replace("_", " "))),
    },
    {
      title: t("common.text.withholdingAccount"),
      dataIndex: "withholding_coa",
      key: "withholding_coa",
      render: (text, record) => record.withholding_coa?.name,
    },
    {
      title: `${t("common.text.withholdingAmount")} (Rp)`,
      dataIndex: "withholding_amount",
      key: "withholding_amount",
      render: (text) => <VuiNumberFormat value={text} />,
    },
    {
      title: t("common.text.attachment"),
      key: "attachment",
      render: (text, record) =>
        record.attachment && (
          <a href={record.attachment.url} target="_blank">
            {record.attachment.name}
          </a>
        ),
    },
  ];

  useEffect(() => {
    dispatch({
      type: BreadcrumbActionType.Update,
      payload: [
        {
          link: "/",
          title: t("common.text.home"),
        },
        {
          link: "/invoice",
          title: t("common.text.invoice"),
        },
        {
          link: `/invoice/${id}`,
          title: detailData?.name,
          isDisabled: true,
        },
      ],
    });
  }, [dispatch, t, detailData?.name, id]);

  useEffect(() => {
    if (id) {
      (async () => {
        await loadDetailData(id, {
          with: [
            "customer",
            "projectManager",
            "project",
            "status",
            "invoiceProducts.product",
            "invoiceProducts.unit",
            "sentType",
            "invoiceEmails",
            "invoicePayments.attachment",
            "invoicePayments.payCoa",
            "invoicePayments.withholdingCoa",
            "contactEmails",
            "contactWhatsapp",
            "template",
          ],
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (detailData) {
      form.setFieldsValue({
        amount: total,
        receive_amount: total,
      });
    }
  }, [detailData, form, total]);

  const handleEdit = () => {
    navigate(`/invoice/${id}/edit`);
  };

  const handleUpload = async (formData: InvoiceUploadFormData) => {
    const normalizeData: InvoiceUploadPayloadType = {
      document_id: formData?.document_id[0]?.id,
    };

    if (id) {
      await onUpload(id, normalizeData);
      setShowUploadForm(false);
      await loadDetailData(id, {
        with: [
          "customer",
          "projectManager",
          "project",
          "status",
          "invoiceProducts.product",
          "invoiceProducts.unit",
          "sentType",
          "invoiceEmails",
          "invoicePayments.attachment",
          "invoicePayments.payCoa",
          "invoicePayments.withholdingCoa",
          "contactEmails",
          "contactWhatsapp",
          "template",
        ],
      });
    }
  };

  const handleDownload = () => {
    if (id) {
      onDownload(id, detailData?.name || "");
    }
  };

  const handleSend = async () => {
    if (id) {
      await onSend(id);
      setShowSendForm(false);
      await loadDetailData(id, {
        with: [
          "customer",
          "projectManager",
          "project",
          "status",
          "invoiceProducts.product",
          "invoiceProducts.unit",
          "sentType",
          "invoiceEmails",
          "invoicePayments.attachment",
          "invoicePayments.payCoa",
          "invoicePayments.withholdingCoa",
          "contactEmails",
          "contactWhatsapp",
          "template",
        ],
      });
    }
  };

  const handleSendReminder = async () => {
    if (id) {
      await onSendReminder(id);
      setShowSendReminderForm(false);
      await loadDetailData(id, {
        with: [
          "customer",
          "projectManager",
          "project",
          "status",
          "invoiceProducts.product",
          "invoiceProducts.unit",
          "sentType",
          "invoiceEmails",
          "invoicePayments.attachment",
          "invoicePayments.payCoa",
          "invoicePayments.withholdingCoa",
          "contactEmails",
          "contactWhatsapp",
          "template",
        ],
      });
    }
  };

  const handleSubmitPaidOff = useCallback(
    async (data: InvoicePaidOffFormData) => {
      if (id) {
        const normalizeData: InvoicePaidOffPayloadType = {
          date: moment(data.date).format("YYYY-MM-DD"),
          pay_coa_id: data.pay_coa_id.value as number,
          payment_method: data.payment_method,
          withholding_coa_id: data?.withholding_coa_id?.value as number,
          withholding_amount:
            withholdingType === "PERCENTAGE" && data.withholding_amount
              ? (data.withholding_amount / 100) * total
              : data.withholding_amount,
          attachment_id:
            data.attachment_id && data.attachment_id.length > 0
              ? data.attachment_id[0].id
              : undefined,
        };

        await onSubmitPaidOff(normalizeData, id);

        setShowPaidOffConfirmation(false);
        await loadDetailData(id, {
          with: [
            "customer",
            "projectManager",
            "project",
            "status",
            "invoiceProducts.product",
            "invoiceProducts.unit",
            "sentType",
            "invoiceEmails",
            "invoicePayments.attachment",
            "invoicePayments.payCoa",
            "invoicePayments.withholdingCoa",
            "contactEmails",
            "contactWhatsapp",
            "template",
          ],
        });
      }
    },
    [id, onSubmitPaidOff, loadDetailData]
  );

  const handleApproval = async () => {
    if (id) {
      await onApproval(id, showApprovalConfirmation === "approve");
      setShowApprovalConfirmation(null);
      await loadDetailData(id, {
        with: [
          "customer",
          "projectManager",
          "project",
          "status",
          "invoiceProducts.product",
          "invoiceProducts.unit",
          "sentType",
          "invoiceEmails",
          "invoicePayments.attachment",
          "invoicePayments.payCoa",
          "invoicePayments.withholdingCoa",
          "contactEmails",
          "contactWhatsapp",
          "template",
        ],
      });
    }
  };

  const handleDelete = async () => {
    if (id) {
      await onDelete(id).then(() => {
        setShowDeleteModal(false);
        openNotification(
          "success",
          t("notification.success.deleteItem", {
            item: t("common.text.invoice"),
          })
        );

        navigate(`/invoice`);
      });
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <VuiPageTitle
            title={
              <Title level={4} style={{ fontWeight: 500, marginBottom: 0 }}>
                {title}
                <Text style={{ fontWeight: 800 }}>
                  {detailData?.name || ""}
                </Text>
              </Title>
            }
            onBackLink="/invoice"
            rightTitleContent={[
              <Tag
                color={_.get(
                  INVOICE_STATUS_COLOR,
                  detailData?.status?.label || "",
                  "blue"
                )}
              >
                {detailData?.status?.label}
              </Tag>,
            ]}
          >
            {detailData?.status?.label === "Waiting For Approval" && (
              <>
                {detailData.can_approve && (
                  <Button
                    size="large"
                    type="primary"
                    ghost
                    onClick={() => setShowApprovalConfirmation("approve")}
                  >
                    Approve
                  </Button>
                )}
                {detailData.can_approve && (
                  <Button
                    size="large"
                    type="primary"
                    danger
                    ghost
                    onClick={() => setShowApprovalConfirmation("reject")}
                  >
                    Reject
                  </Button>
                )}
              </>
            )}

            {detailData?.status?.label === "Waiting For Approval" ? (
              detailData?.can_update && !detailData?.gw_customer_id ? (
                <Button
                  size="large"
                  type="primary"
                  ghost
                  icon={<EditOutlined />}
                  onClick={handleEdit}
                />
              ) : null
            ) : detailData?.can_download ? (
              <Button
                size="large"
                type="primary"
                ghost
                icon={<DownloadOutlined />}
                loading={downloadLoading}
                disabled={downloadLoading}
                onClick={handleDownload}
              />
            ) : null}

            {detailData?.status?.label === "Approved" &&
              detailData?.can_send && (
                <>
                  <VuiAccessible access="invoice.send">
                    <VuiButton
                      title={t("common.button.sendItem", {
                        item: t("common.text.invoice"),
                      })}
                      buttonProps={{
                        onClick: () => {
                          setShowSendForm(true);
                        },
                      }}
                    />
                  </VuiAccessible>
                </>
              )}

            {(detailData?.status?.label === "Sent" ||
              detailData?.status?.label === "Due") && (
              <VuiAccessible access="invoice.sendReminder">
                {detailData?.can_send_reminder && (
                  <VuiButton
                    title={t("common.button.sendReminder")}
                    buttonProps={{
                      onClick: () => {
                        setShowSendReminderForm(true);
                      },
                      type: "primary",
                      ghost: true,
                    }}
                  />
                )}
              </VuiAccessible>
            )}

            {(detailData?.status?.label === "Sent" ||
              detailData?.status?.label === "Due") && (
              <VuiAccessible access="invoice.paidOff">
                {detailData?.can_paid_off && (
                  <VuiButton
                    title={t("common.button.receivePayment")}
                    buttonProps={{
                      onClick: () => {
                        setShowPaidOffConfirmation(true);
                        if (!defaultPay) {
                          loadDefaultPay({
                            type: "PAY_TO",
                            search: "10002",
                            per_page: 1,
                          }).catch();
                        }
                      },
                    }}
                  />
                )}
              </VuiAccessible>
            )}

            {detailData?.status?.label === "Paid" && (
              <VuiButton
                title={t("common.button.viewPayment")}
                buttonProps={{
                  onClick: () => {
                    setShowPaymentModal(true);
                  },
                }}
              />
            )}
          </VuiPageTitle>
        </Col>
      </Row>

      <Spin size="large" spinning={isOnFetchingDetailData}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Card>
              <Row gutter={[48, 16]}>
                <Col xs={24} md={8}>
                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <VuiFormItemView
                        label={t("common.form.client.label")}
                        value={detailData?.customer?.name}
                      />
                    </Col>
                    <Col xs={24}>
                      <VuiFormItemView
                        label={t("common.form.billingAddress.label")}
                        value={detailData?.billing_address}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={8}>
                  <Row gutter={[32, 16]}>
                    <Col xs={24} md={12}>
                      <VuiFormItemView
                        label={t("common.form.invoiceDate.label")}
                        value={moment(detailData?.date).format("DD MMM YYYY")}
                      />
                    </Col>
                    <Col xs={24} md={12}>
                      <VuiFormItemView
                        label={t("common.form.idProject.label")}
                        value={detailData?.project?.code}
                      />
                    </Col>
                    <Col xs={24} md={12}>
                      <VuiFormItemView
                        label={t("common.form.projectManager.label")}
                        value={detailData?.project_manager?.name}
                      />
                    </Col>
                    <Col xs={24} md={12}>
                      <VuiFormItemView
                        label={t("common.form.top.label")}
                        value={`${detailData?.tempo} Day(s)`}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={8}>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={14}>
                      <Row gutter={[16, 16]}>
                        <Col xs={24}>
                          <VuiFormItemView
                            label={t("common.form.sendType.label")}
                            value={detailData?.sent_type.label}
                          />
                        </Col>
                        <Col xs={24}>
                          <VuiFormItemView
                            label={t("common.form.template.label")}
                            value={detailData?.template?.label}
                          />
                        </Col>
                        {!!detailData?.sent_date && (
                          <Col xs={24}>
                            <VuiFormItemView
                              label={t("common.form.sendDate.label")}
                              value={moment(detailData?.sent_date).format(
                                "DD MMM YYYY"
                              )}
                            />
                          </Col>
                        )}
                      </Row>
                    </Col>
                    <Col xs={24} md={10}>
                      <Row gutter={[16, 16]}>
                        <Col xs={24}>
                          <VuiFormItemView
                            label={t("common.form.sentToEmails.label")}
                            value={invoiceContactEmails}
                          />
                        </Col>
                        <Col xs={24}>
                          <VuiFormItemView
                            label={t("common.form.sentToWhatsapp.label")}
                            value={invoiceContactWhatsapp}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24}>
            <Table
              rowKey={"id"}
              columns={invoiceProductColumns}
              dataSource={detailData?.invoice_products || []}
              loading={isOnFetchingDetailData}
              pagination={false}
              size="small"
            />
          </Col>
          <Col xs={24}>
            <div className="text-align-right mt24">
              <div>
                <Row justify="end">
                  <Col
                    xs={24}
                    lg={8}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <table className="table-summary type-one">
                      <tbody>
                        <tr>
                          <td style={{ minWidth: 100 }}>
                            {t("common.text.subtotal")}
                          </td>
                          <td style={{ textAlign: "end" }}>
                            <VuiNumberFormat value={subtotal} prefix={"Rp "} />
                          </td>
                        </tr>
                        {!!hasPPN && (
                          <tr>
                            <td>{t("common.text.ppn")}</td>
                            <td>
                              <VuiNumberFormat
                                className={
                                  "display-flex justify-content-between"
                                }
                                value={ppn}
                                prefix={"Rp "}
                              />
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>
                            <b>{t("common.text.total")}</b>
                          </td>
                          <td>
                            <b>
                              <VuiNumberFormat value={total} prefix={"Rp "} />
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <VuiAccessible access="invoice.destroy">
            {detailData?.can_delete && (
              <Col xs={24}>
                <Text
                  className="cursor-pointer"
                  onClick={() => setShowDeleteModal(true)}
                  title={t("common.text.delete")}
                  type="secondary"
                >
                  {t("common.text.delete")}
                </Text>
              </Col>
            )}
          </VuiAccessible>
        </Row>
      </Spin>

      <Modal
        width={1300}
        open={showPaymentModal}
        onCancel={() => setShowPaymentModal(false)}
        title={t("common.text.payment")}
        footer={
          <>
            <Button
              htmlType="button"
              onClick={() => setShowPaymentModal(false)}
            >
              {t("common.button.cancel")}
            </Button>
          </>
        }
      >
        <Table
          rowKey={"id"}
          columns={invoicePaymentColumns}
          dataSource={detailData?.invoice_payments || []}
          loading={isOnFetchingDetailData}
          pagination={false}
          size="small"
        />
      </Modal>
      <Modal
        width={800}
        open={showPaidOffConfirmation}
        onCancel={() => setShowPaidOffConfirmation(false)}
        title={t("common.text.receivePayment")}
        footer={
          <>
            <Button
              htmlType="button"
              onClick={() => setShowPaidOffConfirmation(false)}
            >
              {t("common.button.cancel")}
            </Button>
            <Button
              onClick={() => form.submit()}
              type="primary"
              loading={paidOffLoading}
            >
              {t("common.button.submit")}
            </Button>
          </>
        }
      >
        <Spin size="large" spinning={isOnFetchingDataDefaultPay}>
          <Form layout="vertical" form={form} onFinish={handleSubmitPaidOff}>
            <Row gutter={[16, 0]}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="date"
                  initialValue={moment()}
                  label={t("common.form.paymentDate.label")}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", {
                        item: t("common.form.paymentDate.label"),
                      }),
                    },
                  ]}
                >
                  <VuiDatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="payment_method"
                  initialValue={"BANK_TRANSFER"}
                  label={t("common.form.paymentMethod.label")}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", {
                        item: t("common.form.paymentMethod.label"),
                      }),
                    },
                  ]}
                >
                  <Select
                    size="large"
                    placeholder={t("common.form.paymentMethod.placeholder")}
                    options={[
                      {
                        label: "Cash",
                        value: "CASH",
                      },
                      {
                        label: "Bank Transfer",
                        value: "BANK_TRANSFER",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="pay_coa_id"
                  label={t("common.form.payToAccount.label")}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", {
                        item: t("common.form.payToAccount.label"),
                      }),
                    },
                  ]}
                >
                  <VuiSelect
                    repository={ChartOfAccountRepository}
                    placeholder={t("common.form.payToAccount.placeholder")}
                    repositoryParams={{
                      type: "PAY_TO",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="amount" label={t("common.form.amount.label")}>
                  <InputNumber
                    disabled
                    size="large"
                    placeholder={t("common.form.amount.placeholder")}
                    formatter={inputNumberThousandSeparator.formatter}
                    parser={inputNumberThousandSeparator.parser}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="withholding_coa_id"
                  label={t("common.form.withholdingAccount.label")}
                >
                  <VuiSelect
                    repository={ChartOfAccountRepository}
                    placeholder={t(
                      "common.form.withholdingAccount.placeholder"
                    )}
                    repositoryParams={{
                      type: "WITHHOLDING",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="withholding_amount"
                  label={t("common.form.withholdingAmount.label")}
                  extra={
                    withholdingType === "PERCENTAGE" && (
                      <VuiNumberFormat
                        value={withholdingRealAmount}
                        prefix="Rp "
                      />
                    )
                  }
                  rules={[
                    {
                      validator: async (_, value) => {
                        const realAmount =
                          withholdingType === "PERCENTAGE"
                            ? (Number(value) / 100) * total
                            : value;

                        if (realAmount > total) {
                          return Promise.reject(
                            t("validation.withholdingAmountMoreThanTotal")
                          );
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    max={withholdingType === "PERCENTAGE" ? 100 : total}
                    size="large"
                    placeholder={t("common.form.amount.placeholder")}
                    addonBefore={
                      <Select
                        value={withholdingType}
                        onChange={(value) => {
                          form.setFieldValue("withholding_amount", 0);
                          setWithholdingType(value);
                          setWithholdingRealAmount(0);
                        }}
                        defaultValue="PERCENTAGE"
                      >
                        <Option value="PERCENTAGE">%</Option>
                        <Option value="AMOUNT">Rp</Option>
                      </Select>
                    }
                    formatter={inputNumberThousandSeparator.formatter}
                    onChange={(value) => {
                      form.setFieldsValue({
                        receive_amount:
                          withholdingType === "PERCENTAGE"
                            ? total - (Number(value) / 100) * total
                            : total - Number(value),
                      });
                      if (withholdingType === "PERCENTAGE") {
                        setWithholdingRealAmount((Number(value) / 100) * total);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="receive_amount"
                  label={t("common.form.receiveAmount.label")}
                >
                  <InputNumber
                    disabled
                    size="large"
                    placeholder={t("common.form.receiveAmount.placeholder")}
                    formatter={inputNumberThousandSeparator.formatter}
                    parser={inputNumberThousandSeparator.parser}
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item name="attachment_id">
                  <VuiUploadImage
                    label={t("common.text.attachment")}
                    accept="application/pdf"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
      <ModalUpload
        title={
          <>
            <Title
              level={5}
              style={{
                color: " #979DAD",
                fontWeight: 400,
              }}
            >
              Upload Invoice
              <br />
              <Title level={4}>{detailData?.project?.code}</Title>
            </Title>
          </>
        }
        show={showUploadForm}
        onClose={() => setShowUploadForm(false)}
        onSubmit={handleUpload}
        submitLoading={uploadLoading}
      />
      <VuiModalConfirmation
        show={showSendForm}
        isLoading={sendLoading}
        onSubmit={handleSend}
        onCancel={() => setShowSendForm(false)}
        isSubmitDanger={false}
        submitBtnLabel={t("common.button.send")}
        title=""
        subtitle={t("modal.invoiceSendConfirmationItem.title", {
          value:
            haveEmailContact && haveWhatsappContact
              ? t("modal.invoiceSendConfirmationItem.emailAndWhatsapp", {
                  emails: invoiceContactEmails,
                  numbers: invoiceContactWhatsapp,
                })
              : haveEmailContact
              ? t("modal.invoiceSendConfirmationItem.email", {
                  emails: invoiceContactEmails,
                })
              : t("modal.invoiceSendConfirmationItem.whatsapp", {
                  numbers: invoiceContactWhatsapp,
                }),
        })}
      />
      <VuiModalConfirmation
        show={showSendReminderForm}
        isLoading={sendReminderLoading}
        onSubmit={handleSendReminder}
        onCancel={() => setShowSendReminderForm(false)}
        isSubmitDanger={false}
        submitBtnLabel={t("common.button.send")}
        title=""
        subtitle={t("modal.invoiceSendReminderConfirmation.title", {
          value:
            haveEmailContact && haveWhatsappContact
              ? t("modal.invoiceSendReminderConfirmation.emailAndWhatsapp", {
                  emails: invoiceContactEmails,
                  numbers: invoiceContactWhatsapp,
                })
              : haveEmailContact
              ? t("modal.invoiceSendReminderConfirmation.email", {
                  emails: invoiceContactEmails,
                })
              : t("modal.invoiceSendReminderConfirmation.whatsapp", {
                  numbers: invoiceContactWhatsapp,
                }),
        })}
      />
      <VuiModalConfirmation
        show={!!showApprovalConfirmation}
        isLoading={isApprovalSubmitting}
        onSubmit={handleApproval}
        onCancel={() => setShowApprovalConfirmation(null)}
        isSubmitDanger={false}
        submitBtnLabel={t("common.button.submit")}
        title=""
        subtitle={
          showApprovalConfirmation === "approve"
            ? t("modal.invoiceApproveConfirmation.title")
            : t("modal.invoiceRejectConfirmation.title")
        }
      />
      <VuiModalConfirmation
        show={showDeleteModal}
        isLoading={deleteLoading}
        onSubmit={handleDelete}
        onCancel={() => setShowDeleteModal(false)}
      />
    </>
  );
};

export default InvoiceDetailModule;
