import api, { createCancelTokenHandler } from "../utils/services/api.service";
import { ENV } from "../constants";

const PageRepository = {
  all(params?: any) {
    return api.get(`${ENV.getApiEndPoint()}/api/page`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show(id: string, params?: any) {
    return api.get(`${ENV.getApiEndPoint()}/api/page/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  update: function (id: number | string, payload: any, params: any = null) {
    return api.put(`${ENV.getApiEndPoint()}/api/page/${id}`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(PageRepository);

export default PageRepository;
