import { DatePicker } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import { useTranslation } from "react-i18next";
import moment from "moment";

const { RangePicker } = DatePicker;

const VuiDateRangePicker = (props: RangePickerProps) => {
  const { t } = useTranslation();
  const {
    ranges = {
      [t("common.dates.today")]: [moment(), moment()],
      [t("common.dates.yesterday")]: [
        moment().subtract(1, "days"),
        moment().subtract(1, "days"),
      ],
      [t("common.dates.lastDays", { days: 7 })]: [
        moment().subtract(7, "days"),
        moment(),
      ],
      [t("common.dates.lastDays", { days: 30 })]: [
        moment().subtract(30, "days"),
        moment(),
      ],
      [t("common.dates.thisMonth")]: [
        moment().startOf("month"),
        moment().endOf("month"),
      ],
      [t("common.dates.thisYear")]: [
        moment().startOf("year"),
        moment().endOf("year"),
      ],
    },
    size = "large",
    allowClear = true,
    format = "DD MMM YYYY",
    ...other
  } = props;

  return (
    <RangePicker
      {...other}
      allowClear={allowClear}
      size={size}
      ranges={ranges}
      format={format}
    />
  );
};

export default VuiDateRangePicker;
