import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/auth";

const DashboardCmsModule = () => {
  const { t } = useTranslation();
  const { state } = useAuth();

  return <></>;
};

export default DashboardCmsModule;
