import { useCallback, useState } from "react";
import { AxiosError } from "axios";
import { handleBackendError } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InboxRepository from "../../../repositories/inbox-repository";

export const useFormInbox = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [readLoading, setReadLoading] = useState<boolean>(false);

  const onRead = useCallback(
    async (id: string) => {
      setReadLoading(true);

      await InboxRepository.read(id)
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setReadLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, navigate]
  );

  return {
    onRead,
    readLoading,
  };
};
