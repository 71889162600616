import { useCallback, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { ICollection } from "../../../utils/interfaces/collection.interface";
import { handleBackendError } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { IPaginationParams } from "../../../utils/interfaces/pagination-params.interface";
import { Invoice } from "../../../models/invoice";
import { IResource } from "../../../utils/interfaces/resource.interface";
import InvoiceRepository from "../../../repositories/invoice-repository";
import { InvoiceDataList } from "../list/interface";

export const useGetFirstData = (repository: any) => {
  const { t } = useTranslation();
  const [isOnFetchingData, setIsOnFetchingData] = useState<boolean>(false);
  const [data, setData] = useState<any | null>(null);

  const loadData = useCallback(async (params: any) => {
    setIsOnFetchingData(true);

    await repository
      .select(params)
      .then((response: AxiosResponse<ICollection<any[]>>) => {
        const { data } = response.data;

        if (data.length > 0) {
          setData(data[0]);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsOnFetchingData(false);
      });
  }, []);

  return {
    loadData,
    isOnFetchingData,
    data,
  };
};
