import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useBreadcrumb } from "../../../context/breadcrumb";
import { BreadcrumbActionType } from "../../../context/breadcrumb/reducer";
import VuiHelmet from "../../../components/helmet";
import ProjectTypeListModule from "../../../modules/project-type/list";
import SideMenuLayout from "../../../layouts/side-menu";
import { generateOtherMenu } from "../../../constants/other-menu";

const ProjectTypeListPage = () => {
  const { t } = useTranslation();
  const { dispatch } = useBreadcrumb();
  const sideMenus = generateOtherMenu(t);

  useEffect(() => {
    dispatch({
      type: BreadcrumbActionType.Update,
      payload: [
        {
          link: "/",
          title: t("common.text.home"),
        },
        {
          link: "/other-list",
          title: t("common.text.otherList"),
          isDisabled: true,
        },
        {
          link: "/project-type",
          title: t("common.text.projectType"),
          isDisabled: true,
        },
      ],
    });
  }, [dispatch, t]);

  return (
    <>
      <VuiHelmet title={t("pages.projectType.list.metaTitle")} />

      <SideMenuLayout menus={sideMenus}>
        <ProjectTypeListModule />
      </SideMenuLayout>
    </>
  );
};

export default ProjectTypeListPage;
