import { useTranslation } from "react-i18next";
import VuiHelmet from "../../../components/helmet";
import LeadDetailModule from "../../../modules/lead/detail";

const LeadDetailPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.lead.detail.metaTitle")} />

      <LeadDetailModule />
    </>
  );
};

export default LeadDetailPage;
