import { useCallback, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { handleBackendError } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import InvoiceRepository from "../../../repositories/invoice-repository";
import fileDownload from "js-file-download";
import moment from "moment";

export type ExportInvoiceType = "sales-invoice" | "sales-payment";

export const useExportInvoice = () => {
  const { t } = useTranslation();
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  const onExport = useCallback(
    async (type: ExportInvoiceType, params: any) => {
      setDownloadLoading(true);

      const { start_date, end_date } = params;
      const exportFunction =
        type === "sales-invoice"
          ? InvoiceRepository.exportSalesInvoice
          : InvoiceRepository.exportSalesPayment;

      await exportFunction(params)
        .then((response: AxiosResponse) => {
          fileDownload(
            response.data,
            `${type}-${moment(start_date).format("YYYY|MM|DD")}-${moment(
              end_date
            ).format("YYYY|MM|DD")}.csv`
          );
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setDownloadLoading(false);
        });
    },
    [t]
  );

  return {
    onExport,
    downloadLoading,
  };
};
